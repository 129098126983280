<template>
  <section class="index-section s-products--slider">
    <div class="container">
      <div class="s-title">{{ title }}</div>
      <div class="__slider">
        <swiper
          v-if="products && products.length"
          slidesPerView="auto"
          :spaceBetween="20"
          :loop="true"
          :pagination="{
            el: '.products-pagination',
            clickable: true,
          }"
          :navigation="{
            nextEl: '.products-next',
            prevEl: '.products-prev',
          }"
          :breakpoints="productsBreakpoints"
        >
          <swiper-slide v-for="product in products" :key="product.id">
            <product-card
              :imagePath="product.back_middle_image ? product.back_middle_image : product.back_image"
              :link="`/product/${product.slug}`"
              :people="product.label_people"
              :price="product.price"
              :time="product.label_duration"
              :title="product.name"
              :description="product.label_description"
            />
          </swiper-slide>

          <div class="swiper-pagination products-pagination"></div>
          <div class="swiper-button-prev products-prev"></div>
          <div class="swiper-button-next products-next"></div>
        </swiper>

        <swiper
          v-else
          slidesPerView="auto"
          :spaceBetween="20"
          :loop="true"
          :pagination="{
            el: '.products-pagination',
            clickable: true,
          }"
          :navigation="{
            nextEl: '.products-next',
            prevEl: '.products-prev',
          }"
          :breakpoints="productsBreakpoints"
        >
          <swiper-slide v-for="product in popularProducts" :key="product.id">
            <product-card
              :imagePath="product.back_middle_image ? product.back_middle_image : product.back_image"
              :link="`/product/${product.slug}`"
              :people="product.label_people"
              :price="product.price"
              :time="product.label_duration"
              :title="product.name"
              :description="product.label_description"
            />
          </swiper-slide>

          <div class="swiper-pagination products-pagination"></div>
          <div class="swiper-button-prev products-prev"></div>
          <div class="swiper-button-next products-next"></div>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import Sw from "swiper";

Sw.use([Navigation, Pagination]);

import "swiper/swiper-bundle.min.css";
import ProductCard from "./../../product-card/ProductCard.vue";
import { computed, onBeforeMount, toRef } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
  name: "products-slider",
  props: {
    products: {
      type: [Array, Object]
    },
    title: {
      type: String,
      default: "Наши услуги",
    },
  },
  inject: ['backUrl'],
  setup(props) {
    let productsProps = toRef(props, 'products')
    const store = useStore();
    const popularProducts = computed(
      () => store.state.products.popularProducts
    );

    const productsBreakpoints = {
      365: {
        slidesPerView: 1.4,
      },
      525: {
        slidesPerView: 2,
      },
      1024: {
        spaceBetween: 30,
        slidesPerView: 3,
      },
    };

    onBeforeMount(() => {
      if(!productsProps.value && !popularProducts.value){
        store.dispatch("products/getPopularProducts")
      }
    })

    return {
      productsBreakpoints,
      popularProducts,
      productsProps
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    ProductCard
  },
};
</script>

<style scoped>
</style>