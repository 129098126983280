<template>
  <div class="card-sm display--flex">
    <div class="card-header display--flex">
      <router-link :to="link" class="card-title display--flex">
        <img class="card-image" :src="imagePath" alt="" />
        <span>{{ title }}</span>
      <div class="card-meta display--flex">
        <div v-if="people" class="__item display--flex">
          <img src="@/assets/images/icons/users-icon.svg" alt="" />
          {{ people }}
        </div>
        <div v-if="time" class="__item display--flex">
          <img src="@/assets/images/icons/clock-icon.svg" alt="" />
          {{ time }}
        </div>
        <div
          v-if="description && (!people || !time)"
          class="__item display--flex"
        >
          <i class="fal fa-comments"></i>
          {{ description }}
        </div>
      </div>
      </router-link>
    </div>
    <div class="card-footer display--flex">
      <div class="card-price">{{ price }} ₽</div>
      <router-link
        :to="link"
        class="btn btn-sm btn-o"
        >Подробнее</router-link
      >
    </div>
  </div>
</template>

<script>
import { toRef } from "@vue/reactivity";
import { useStore } from "vuex";
export default {
  name: "product-card",

  setup(props) {
    const store = useStore();

    let category = toRef(props, "category");

    let checkComplex = () => {
      if (!category.value || category.value !== "Пакетные решения") {
        store.commit("products/setIsComplex", false);
      } else {
        store.commit("products/setIsComplex", true);
      }
    };

    return {
      checkComplex
    };
  },

  props: {
    imagePath: {
      type: String,
    },
    title: {
      type: String,
    },
    price: {
      type: Number,
    },
    link: {
      type: String,
    },
    time: {
      type: String,
    },
    people: {
      type: String,
    },
    category: {
      type: String,
    },
    description: {
      type: String
    }
  },
};
</script>

<style lang="scss" scoped>
.card-sm {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.card-sm:hover {
  box-shadow: 0 4px 20px rgba(182, 196, 214, 0.5);
}

.card-sm .card-header {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.card-sm .card-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: 182px;
  margin-bottom: 15px;
  border-radius: 4px;
  object-fit: cover;
}

.card-sm .card-title {
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
  flex-grow: 1;
  width: 100%;
}

.card-header a {
  color: var(--color);
  text-decoration: none;
  flex-direction: column;
}

.card-sm span{
  flex-grow: 1;
  margin-bottom: 15px;
}

.card-header a:hover {
  color: var(--accent);
}

.card-sm .card-footer {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 15px;
  border-top: 1px solid var(--border-color);
}

.card-sm .card-price {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
}

.card-meta {
  align-items: center;
  justify-content: flex-start;
  color: var(--color-light-2);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

.card-meta > .__item {
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}

.card-meta > .__item + .__item {
  margin-left: 8px;
}

.card-meta img {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

@media (max-width: 767px) {
  .card-sm {
    padding: 12px;
  }

  .card-sm .card-image {
    height: 134px;
  }

  .card-sm .card-title {
    font-size: 18px;
    line-height: 22px;
  }

  .card-sm .card-price {
    font-size: 18px;
    font-weight: 700;
    line-height: 1em;
  }

  .card-sm .card-footer .btn {
    display: none;
  }

  .card-meta {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .card-meta > .__item + .__item {
    margin-left: 0;
  }

  .s-title {
    margin-bottom: 24px;
  }
}

@media (max-width: 400px) {
  .card-sm {
    width: 230px;
  }
}
</style>